import { externalPaths } from "pathnames";

export const config = {
  columns: [
    {
      title: "Pagalba",
      expandable: true,
      links: [
        {
          href: "https://pages.audioteka.com/lt/start/",
          text: "Susipažinkite su Audioteka",
        },
        {
          route: "helpCenterWithQuestion",
          params: {
            categoryId: "kontaktai",
            questionId: "kontaktai",
          },
          text: "Kontaktai",
        },
        { route: "helpCenter", text: "Dažnai užduodami klausimai" },
        {
          href: "https://audioteka.com/lt/buyers-guide",
          text: "Kaip pirkti",
        },
        { route: "privacyPolicy", text: "Privatumo politika" },
        {
          href: "https://audioteka.com/lt/terms-of-use/",
          text: "Audioteka terminai ir sąlygos",
        },
        {
          href: "https://audioteka.com/lt/review-terms-and-conditions",
          text: "Atsiliepimų taisyklės ",
        },
        { className: "manageGdprButton", text: "Išplėstiniai nustatymai" },
      ],
    },
    {
      title: "Naudingos nuorodos",
      expandable: true,
      links: [
        { href: externalPaths.clubLandingPage.lt, text: "Audioteka Club prenumerata" },
        { href: "https://audioteka.com/lt/v2/wa-account", text: "Aktyvuoti / Nutraukti prenumeratą" },
        { href: "https://www.audioknygos.lt/parduotuve/", text: "Dovanų kuponai" },
        { href: "https://www.audioknygos.lt/netrukus-audiotekoje/", text: "Greitu metu Audiotekoje" },
        { href: "https://www.audioknygos.lt/audioteka-naujienlaiskis/", text: "Prenumeruoti naujienlaiškį" },
        { href: "https://audioteka-verslui.lt/", text: "Audioteka verslui" },
        { href: "https://welcome.audioteka.com/", text: "Audioteka kitose šalyse" },
      ],
    },
    {
      title: "Programėlės",
      apps: {
        android: "https://play.google.com/store/apps/details?id=com.audioteka&hl=lt",
        ios: "https://apps.apple.com/us/app/audioteka/id1107917398",
      },
    },
  ],
  social: [
    {
      href: "https://www.facebook.com/audioteka.lt",
      icon: "facebook",
    },
    {
      href: "https://www.youtube.com/@AudiotekaLt",
      icon: "youtube",
    },
    {
      href: "https://www.instagram.com/audioteka.lt/",
      icon: "instagram",
    },
  ],
  copy: `Copyright © 2008-${new Date().getFullYear()} Audioteka Group Sp. z o.o.`,
};
