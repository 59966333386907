import React from "react";
import { useTranslations } from "next-intl";

import { usePlayer } from "modules/player";
import { NewIcon } from "ui/NewIcon";
import { Teaser } from "./teaser";

export function PlayButton({ slug }: { slug: string }) {
  const player = usePlayer();
  const t = useTranslations("teaser");

  return (
    <Teaser.ActionButton onClick={() => player.load(slug, { autoPlay: true })} ariaLabel={t("play")}>
      <NewIcon icon="play" width="20" height="20" />
    </Teaser.ActionButton>
  );
}
